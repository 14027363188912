<template>
    <div class="p-relative">
            <img src="@/assets/img/circle.png" class="circle-image">
            <img src="@/assets/img/check.png" class="absolute-x-center first-check" ref="checkPayment">
         </div>
</template>
<script>
export default {
  mounted () {
    setTimeout(() => {
      this.$refs.checkPayment.classList.add('animate-check')
    }, 500)
  }
}
</script>
