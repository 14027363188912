<template>
    <div class="cell"  :class="[...classSet, opacity ? 'opacity-5': '']" v-html="textData"></div>
</template>

<script>
export default {
  props: {
    classSet: {
      type: Array,
      default: () => []
    },
    textAntrian: {
      type: [String, Number],
      default: ''
    },
    opacity: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    textData () {
      return this.$l.detectLanguage(this.textAntrian)
    }
  }
}
</script>

<style>

</style>
